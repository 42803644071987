import { Wallet } from './types';
import { TaxReport } from './types';

export const mockWallets: Wallet[] = [
    {
        id: 1,
        type: 'Binance',
        name: 'My Binance',
        transactions: 4765,
        synced: 'Just now',
    },
    {
        id: 2,
        type: 'Coinbase',
        name: 'My Coinbase',
        transactions: 369,
        synced: 'Just now',
    },
];

export const mockTaxReports: TaxReport[] = [
    {
        id: 1,
        date: '22.02.2022',
        totalGains: 15460.0,
        taxesToPay: 749.0,
        stats: [
            {
                name: 'transactions',
                value: 6254,
            },
            {
                name: 'deposits',
                value: 3213,
            },
            {
                name: 'withdrawals',
                value: 352,
            },
            {
                name: 'trades',
                value: 7,
            },
        ],
        breakdown: [
            {
                name: 'Capital gains/ P&L',
                value: 1900.55,
            },
            {
                name: 'Other gains (futures, derivates)',
                value: 13560.45,
            },
            {
                name: 'Income',
                value: -4.89,
            },
            {
                name: 'Costs & expences',
                value: 0.0,
            },
            {
                name: 'Gifts, donations, lost coin',
                value: 0.0,
            },
        ],
        taxReportPDF: '', // PLACEHOLDER
    },
    {
        id: 2,
        date: '13.08.2021',
        totalGains: 8410.0,
        taxesToPay: 349.0,
        stats: [
            {
                name: 'transactions',
                value: 6254,
            },
            {
                name: 'deposits',
                value: 3213,
            },
            {
                name: 'withdrawals',
                value: 352,
            },
        ],
        breakdown: [
            {
                name: 'Capital gains/ P&L',
                value: 1900.55,
            },
            {
                name: 'Other gains (futures, derivates)',
                value: 13560.45,
            },
            {
                name: 'Income',
                value: -4.89,
            },
            {
                name: 'Costs & expences',
                value: 0.0,
            },
            {
                name: 'Gifts, donations, lost coin',
                value: 0.0,
            },
        ],
        taxReportPDF: '', // PLACEHOLDER
    },
    {
        id: 3,
        date: '24.12.2020',
        totalGains: 2290.0,
        taxesToPay: 69.0,
        stats: [
            {
                name: 'transactions',
                value: 6254,
            },
            {
                name: 'deposits',
                value: 3213,
            },
            {
                name: 'withdrawals',
                value: 352,
            },
        ],
        breakdown: [
            {
                name: 'Capital gains/ P&L',
                value: 1900.55,
            },
            {
                name: 'Other gains (futures, derivates)',
                value: 13560.45,
            },
            {
                name: 'Income',
                value: -4.89,
            },
            {
                name: 'Costs & expences',
                value: 0.0,
            },
            {
                name: 'Gifts, donations, lost coin',
                value: 0.0,
            },
        ],
        taxReportPDF: '', // PLACEHOLDER
    },
];
