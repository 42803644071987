import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DarkButton } from '../components/Buttons';
import { BitaxAPIClient } from '../services/BitaxApiClient';

export default function Testing() {
    const { t } = useTranslation('bitax');

    // TODO: move URL to config
    const client = new BitaxAPIClient('http://localhost:3000');

    const [jSONData, setJSONData] = useState<object>();

    async function getAboutData() {
        const about = await client.about();
        setJSONData(about);
    }

    return (
        <Grid container sx={{ marginTop: '5rem', justifyContent: 'center' }}>
            <Grid item>
                <Typography variant="h1">{t('testing.header')}</Typography>
                <Typography variant="body1">
          This page contains development time stuff for testing incomplete
          features.
                </Typography>
            </Grid>
            <Grid marginTop={10} item xs={12}>
                <Typography variant="h2">Weather API test</Typography>
                <Typography variant="body1">
          API use examples. You have to login first to test API
                </Typography>
                <DarkButton onClick={getAboutData}>Test API</DarkButton>
                <Typography variant="body2">
                    {JSON.stringify(jSONData, null, 2)}
                </Typography>
            </Grid>
        </Grid>
    );
}
