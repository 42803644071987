import { styled, Typography } from '@mui/material';

export const borderRadius = 24;
export const bitaxTints = {
    darkBackground: '#080A0C',
    buttonGrey: '#181E25', // Button grey
    buttonBlue: '#3D8DFF', // Button blue
    white: '#FFFFFF',
    secondaryText: '#8FA2B7',
    grey2: '#E7EBEF',
    grey6: '#B4C0CF',
};

export const bitaxGradients = {
    lightBlueMain:
    'linear-gradient(92deg, rgba(86, 145, 255, 1) 0%, rgba(68, 235, 202, 1) 100%)',
    lightBlueTransparent:
    'linear-gradient(180deg, rgba(7, 133, 255, 0.14) 1.04%, rgba(7, 133, 255, 0.03) 100%)',
    textGradient:
    'font-size: 72px; background: -webkit-linear-gradient(#eee, #333); -webkit-background-clip: text; -webkit-text-fill-color: transparent;'
};

export const GradientText = styled(Typography)({
    background: bitaxGradients.lightBlueMain,
    '-webkit-background-clip': 'text; -webkit-text-fill-color: transparent'
});