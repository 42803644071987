import { ReactComponent as BinanceLogo } from '../assets/icons/binance-logo.svg';
import { ReactComponent as CoinbaseLogo } from '../assets/icons/coinbase-logo.svg';
import { ReactComponent as BitaxWalletLogo } from '../assets/icons/bitax-wallet-logo.svg';
import { UserDto, WalletDto } from '../services/BitaxApiClient';

export type Wallet = {
  id: number;
  type: string;
  name: string;
  transactions: number;
  synced: string; // PLACEHOLDER
};

export type WalletType = {
  name: string;
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export type TaxReport = {
  id: number;
  date: string; // PLACEHOLDER
  totalGains: number;
  taxesToPay: number;
  stats: NameValue[];
  breakdown: NameValue[];
  taxReportPDF: string; // PLACEHOLDER
};

export type NameValue = {
  name: string;
  value: number;
};

export type Language = {
  key: number;
  icon: JSX.Element;
  name: string;
};

export type BitaxContextType = {
  generating: boolean,
  user: UserDto[],
  wallets: WalletDto[],
  updateGenerating: (updateGenerating: boolean) => void;
  updateUser: (updatedUser: UserDto[]) => void;
  updateWallets: (updatedWallets: WalletDto[]) => void;
}

export const walletTypes = [
    { name: 'wallets.names.manual', logo: BitaxWalletLogo },
    { name: 'wallets.names.binance', logo: BinanceLogo },
    { name: 'wallets.names.coinbase', logo: CoinbaseLogo },
];