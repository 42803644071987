import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import BitaxLogo from '../assets/icons/bitax-logo.svg';
import { ShinyButtonWhite } from './Buttons';
import { useMsal } from '@azure/msal-react';
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/material/styles'
import { loginRequest } from '../authentication/authConfig';
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from 'react-i18next';
import { bitaxTints } from '../styles/constants';
import '../styles/landingAnimations.css';


const breatheAnimation = keyframes`
    0% { scale: 1 }
    50% { scale: 1.15 }
    100% { scale: 1 }
`
const ShinyButtonWhiteWithPulse = styled(ShinyButtonWhite)({
    animationName: breatheAnimation,
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    '&:hover':{
        animation: 'none',
    },
})

export default function LoginLander() {
    const { t } = useTranslation('bitax');
    const { instance } = useMsal();
    
    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });
    };

    return(
        <div className="area" >
            {/* This list is here just for the background animation. */}
            <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>

            <Grid container sx={{ pt: '5rem', justifyContent: 'center' }}>
                <Grid container item spacing={3} sx={{ maxWidth: '32rem', justifyContent: 'center' }}>

                    {/* Bitax logo */}
                    <Grid item xs={12}>
                        <img src={BitaxLogo} alt="Bitax logo" width={100}/>
                    </Grid>

                    {/* Header Text */}
                    <Grid item xs={12}>
                        <Typography variant='h1'>
                            {t('login.welcome')} {t('login.bitax')}
                        </Typography>
                    </Grid>

                    {/* SubHeader Text */}
                    <Grid item xs={12}>
                        <Typography variant='h3' color={bitaxTints.secondaryText}>
                            {t('login.less_cryptic')}
                        </Typography>
                    </Grid>

                    {/* Login Button */}
                    <Grid item xs={12} sx={{mt: '5rem'}}>
                        <ShinyButtonWhiteWithPulse
                            onClick={() => handleLogin()}
                            startIcon={<LoginIcon />}
                        >
                            {t('navbar.login')}
                        </ShinyButtonWhiteWithPulse>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}
