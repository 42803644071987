import React, { createContext, useContext, useState } from 'react';
import { UserDto, WalletDto } from '../services/BitaxApiClient';
import { BitaxContextType } from './types';

// Create the context
const BitaxContext = createContext<BitaxContextType | undefined>(undefined);

// Create a custom hook to access the context
const useBitaxContext = (): BitaxContextType => {
    const context = useContext(BitaxContext);
    if (!context) {
        throw new Error('useBitaxContext must be used within a BitaxProvider');
    }
    return context;
};

// BitaxProvider component to wrap your app and provide the context values
const BitaxProvider: React.FC = ({ children }) => {
    const [generating, setGenerating] = useState(false);
    const [user, setUser] = useState<UserDto[]>([]);
    const [wallets, setWallets] = useState<WalletDto[]>([]);

    const updateGenerating = (updatedGenerating: boolean) => {
        setGenerating(updatedGenerating);
    };

    const updateUser = (updatedUser: UserDto[]) => {
        setUser(updatedUser);
    };

    const updateWallets = (updatedWallets: WalletDto[]) => {
        setWallets(updatedWallets);
    };

    const value: BitaxContextType = {
        generating,
        user,
        wallets,
        updateGenerating,
        updateUser,
        updateWallets,
    };

    return <BitaxContext.Provider value={value}>{children}</BitaxContext.Provider>;
};

export { BitaxProvider, useBitaxContext };