import {
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    SvgIcon,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    WalletListItemButton,
    WalletListItem,
    NoticeTypography,
    WalletSecondaryActionTypography,
} from '../walletStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Wallet, WalletType } from '../../../store/types';
import { DialogPage } from './WalletDialog';

export type WalletSelectProps = {
    setPage: React.Dispatch<React.SetStateAction<number>>;
    handleClose: () => void;
    walletTypes: WalletType[];
    usersWallets: Wallet[];
    setSelectedWallet: (wallet: WalletType) => void;
};

export const WalletSelect = ({
    setPage,
    handleClose,
    walletTypes,
    setSelectedWallet,
}: WalletSelectProps) => {
    const { t } = useTranslation('bitax');

    const selectWallet = (walletType: WalletType) => {
        setSelectedWallet(walletType);
        setPage(DialogPage.SETUP_WALLET);
    };
    return (
        <>
            <DialogTitle sx={{ textAlign: 'center' }}>
                {t('wallets.select_the_type_of_wallet')}{' '}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <List>
                    {walletTypes.map((wallet, index) => (
                        <WalletListItem
                            disablePadding
                            key={wallet.name + index}
                            secondaryAction={
                                <WalletSecondaryActionTypography variant="body2">
                                    {index === 0
                                        ? t('wallets.manual_setup')
                                        : t('wallets.automatic_setup')}
                                </WalletSecondaryActionTypography>
                            }
                        >
                            {/* Remove disabled={index > 0} when implementing API wallets */}
                            <WalletListItemButton key={wallet.name + index + 'button'} disabled={index > 0} onClick={() => selectWallet(wallet)}>
                                <ListItemIcon>
                                    <SvgIcon
                                        component={wallet.logo}
                                        inheritViewBox
                                        sx={{ height: '35px', width: '35px' }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        index === 0
                                            ? t(wallet.name)
                                            : t(wallet.name) + ' ' + t('wallets.wallet')
                                    }
                                    primaryTypographyProps={{
                                        fontSize: '1rem', // 16px
                                        fontWeight: 600,
                                        lineHeight: '1.5rem',
                                    }}
                                />
                            </WalletListItemButton>
                        </WalletListItem>
                    ))}
                </List>
                <NoticeTypography variant="body2" textAlign="center">
                    {t('wallets.notice')}
                </NoticeTypography>
            </DialogContent>
        </>
    );
};
