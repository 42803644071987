import { ThemeOptions } from '@mui/material';
import { borderRadius } from './constants';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#181E25',
        },
        info: {
            main: '#3D8DFF', // Button blue
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#E7EBEF',
            disabled: '#BDBDBD',
        },
        background: {
            default: '#080A0C',
        },
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },

    typography: {
        fontFamily: '"Roboto","Helvetica", "Arial", sans-serif',
        fontSize: 16,
        h1: {
            fontSize: '3rem', // 48px
            fontWeight: 300,
            lineHeight: '3.5rem',
        },
        h2: {
            fontSize: '2.125rem', // 34px
            fontWeight: 300,
            lineHeight: '2.625rem',
        },
        h3: {
            fontSize: '1.5rem', // 24px
            fontWeight: 300,
            lineHeight: '2.25rem',
        },
        subtitle1: {
            fontSize: '1.125rem', // 18px
            fontWeight: 600,
            lineHeight: '1.75rem',
        },
        subtitle2: {
            fontSize: '1rem', // 16px
            fontWeight: 600,
            lineHeight: '1.5rem',
        },
        body1: {
            fontSize: '0.875rem', // 14px
            lineHeight: '1.5rem',
            fontWeight: 600,
        },
        body2: {
            fontSize: '0.875rem', // 14px
            lineHeight: '1.5rem',
            fontWeight: 400,
        },
        button: {
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '1.5rem',
        },
    },
    shape: {
        borderRadius,
    },
    components: {
    // Name of the component
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTrasnform: 'unset !important',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple!
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTrasnform: 'unset !important',
                },
            },
        },
    },
};
export const themeOptionsLight: ThemeOptions = {
    ...themeOptions,
    palette: {
        primary: {
            main: '#181E25',
        },
        action: {
            active: '#181E25', // Button grey
        },
        info: {
            main: '#3D8DFF', // Button blue
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#E7EBEF',
            disabled: '#BDBDBD',
        },
        background: {
            default: '#FFFFFF',
        },
    },
};
