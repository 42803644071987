import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import bitaxFI from './translations/fi/bitax.json';
import bitaxEN from './translations/en/bitax.json';
import { I18nextProvider } from 'react-i18next';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authentication/authConfig';

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'fi', // language to use
    resources: {
        fi: {
            bitax: bitaxFI, // 'bitax' is our custom namespace
        },
        en: {
            bitax: bitaxEN,
        },
    },
});

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
