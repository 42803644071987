/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DarkButtonWhite, ShinyButtonWhite } from '../../components/Buttons';
import AddIcon from '@mui/icons-material/Add';
import { walletTypes } from '../../store/types';
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/material/styles'
import { bitaxTints, GradientText } from '../../styles/constants';
import SyncIcon from '@mui/icons-material/Sync';
import SvgIcon from '@mui/icons-material/Menu';
import SavingsIcon from '@mui/icons-material/Savings';
import CalculateIcon from '@mui/icons-material/Calculate';
import TutorialArrow from '../../assets/tutorialArrow.svg';
import { WalletDialog } from './pageComponents/WalletDialog';
import { AlignedText, StyledListWalletBox } from './walletStyles';
import { BitaxAPIClient, WalletDto } from '../../services/BitaxApiClient';
import { DateTime } from 'luxon';
import {WalletMenuButton} from './pageComponents/WalletMenuButton';
import { useBitaxContext } from '../../store/BitaxContext';


const StyledCalculateIcon = styled(CalculateIcon)({
    '& path': {
        fill: bitaxTints.darkBackground,
    },
});

const breatheAnimation = keyframes`
    0% { scale: 1 }
    50% { scale: 1.15 }
    100% { scale: 1 }
`
const ShinyButtonWhiteWithPulse = styled(ShinyButtonWhite)({
    animationName: breatheAnimation,
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    '&:hover':{
        animation: 'none',
    },
})

const GradientIcon = () => (
    <>
        <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={0} y1={0} x2={1} y2={0}>
                <stop offset={0} stopColor="rgba(86, 145, 255, 1)" />
                <stop offset={1} stopColor="rgba(68, 235, 202, 1)" />
            </linearGradient>
        </svg>
        <SavingsIcon sx={{ fill: 'url(#linearColors)', mr: '1.5rem', scale: '1.7'}} />
    </>
)

export default function Wallets() {

    // TODO: move URL to config
    const client = new BitaxAPIClient('http://localhost:3000');

    const { t } = useTranslation('bitax');

    // Add wallet Dialog handlers ---
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const { wallets, updateWallets, updateGenerating } = useBitaxContext();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // --

    const handleCalculateTax = async () => {
        location.href = '/tax-reports';
        updateGenerating(true)
        await client.taxReportsGenerate();
        updateGenerating(false)
    }

    const GetUserWallets = async () => {
        setIsLoading(true)
        const wallets = await client.walletsGet();
        updateWallets(wallets);
        setIsLoading(false)
    }
    useEffect(() => {
        GetUserWallets();
    },[])

    return (
        <Grid container sx={{ pt: '5rem', pl:'0.5rem', pr:'0.5rem', justifyContent: 'center' }}>
            <Grid container item sx={{ maxWidth: '32rem' }}>
                <Grid container item sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12} sm>
                        <Box sx={{display: 'flex', alignItems: 'center', mx: {xs: 'auto', sm: '0px'}, width: {xs: 'fit-content', sm: 'auto'}}}>
                            <GradientIcon />
                            <Typography variant="h1">{t('navbar.wallets')}</Typography>
                        </Box>
                    </Grid>
                    {isLoading === false && (
                        <Grid item xs={12} sm textAlign='end'>
                            {wallets?.length === 0 ? (
                                <ShinyButtonWhiteWithPulse startIcon={<AddIcon />} onClick={handleClickOpen} sx={{width: {xs: '-webkit-fill-available', sm: 'inherit'}}}>
                                    {t('wallets.add_wallet')}
                                </ShinyButtonWhiteWithPulse>
                            ):(
                                <DarkButtonWhite 
                                    startIcon={<AddIcon />} 
                                    onClick={handleClickOpen} 
                                    disabled={wallets === null} 
                                    sx={{width: {xs: '-webkit-fill-available', sm: 'inherit'}, mt: {xs: '2rem', sm: '0rem'}}}>
                                    {t('wallets.add_wallet')}
                                </DarkButtonWhite>
                            ) }
                        </Grid>
                    )}
                    
                </Grid>
                {isLoading === true ? (
                    <Grid container item sx={{ marginTop: '2.25rem', height: '10rem', width: 'inherit', justifyContent: 'center' }}>
                        <Grid item xs={12} sx={{ display: 'grid', alignContent: 'center', justifyContent: 'center', color: bitaxTints.buttonBlue }}>
                            <CircularProgress color='inherit'/>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'grid', height: 'fit-content', alignContent: 'center', color: bitaxTints.buttonBlue }}>
                        
                            <Typography>{t('general.generating')}</Typography>
                        </Grid>
                    </Grid>
                ):(
                    <>
                        <Grid container item>
                            {wallets?.map((wallet: WalletDto, index) => (
                                // One instance of a wallet card.
                                <StyledListWalletBox
                                    key={`wallets${wallet.id}`}
                                    container
                                    item
                                    sx={
                                        index === 0
                                            ? { marginTop: '2.25rem', width: 'inherit' }
                                            : { marginTop: '1.5rem', width: 'inherit' }
                                    }
                                >
                                    <Grid
                                        container
                                        item
                                        sx={{ margin: {xs:'1rem 1.25rem', sm:'1rem 2.25rem'}, alignItems: 'center' }}
                                    >
                                        {/* Left section */}
                                        <Grid item xs>
                                            <AlignedText variant="body1">{wallet.userComments}</AlignedText>
                                            <GradientText variant="h2" sx={{textAlign: 'initial'}}>{wallet.transactionSummary?.profit ?? '-'}</GradientText>
                                            <AlignedText variant="body2">{wallet.csvConfiguration ? 'CSV' : 'Exchange'}</AlignedText>
                                        </Grid>

                                        {/* Mid section */}
                                        <Grid item xs sx={{ display: {xs:'none', sm:'block'}, justifyContent: 'center', alignContent: 'center', color: bitaxTints.secondaryText }}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <SyncIcon sx={{width: '1.2rem' , height: '1.2rem', alignSelf: 'center', marginRight: '6px' }} />
                                                <Typography variant="body1">
                                                    {wallet.lastSyncedTimestamp !== undefined 
                                                    // TODO: Make the locale of .toRelative get the currently used language from Redux.
                                                        ? DateTime.fromJSDate(wallet.lastSyncedTimestamp!).toRelative({locale: 'fi'}) 
                                                        : DateTime.fromJSDate(wallet.createdTimestamp!).toRelative({locale: 'fi'})}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        {/* Right section */}
                                        <Grid item container alignContent='center' xs={2} sm={1}>
                                            <SvgIcon
                                            // TODO: When we get the type of wallet from API, change this to check the type of the current wallet.
                                                component={walletTypes[0].logo}
                                                inheritViewBox
                                                sx={{ height: '35px', width: '35px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <WalletMenuButton wallet={wallet} />
                                        </Grid>
                                    </Grid>
                                </StyledListWalletBox>
                            ))}
                    
                            {(wallets === null || wallets.length === 0) && (
                                <>
                                    <Box sx={{ width: 'inherit', textAlign: 'end', pr: '5rem', pt: '1rem'}}>
                                        <img src={TutorialArrow} alt="React Logo" />
                                    </Box>
                            
                                    <StyledListWalletBox
                                        id='Hey'
                                        key={'tutorial-box'}
                                        container
                                        item
                                        sx={{ marginTop: '0.5rem', width: 'inherit', border: '2px dashed #8FA2B7' }}
                                    >
                                        <Grid
                                            container
                                            item
                                            sx={{ margin: '1rem 2.25rem', alignItems: 'center' }}
                                        >
                                            <Grid item xs sx={{justifyContent: 'center', alignContent: 'center', color: bitaxTints.secondaryText }}>
                                                <Typography variant="body1">
                                                    {t('wallets.welcome')}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {t('wallets.tutorial')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </StyledListWalletBox>
                                </>
                            )}
                        </Grid>
                
                
                        <Grid item container>
                            <ShinyButtonWhite 
                                sx={{ display: wallets !== null && wallets!.length > 0 ? 'flex': 'none', width: 'inherit', marginTop: '3.5rem' }}
                                onClick={handleCalculateTax}    
                            >
                                <StyledCalculateIcon sx={{height: '24px', mr: 1}} />
                                {t('wallets.calculate_tax')}
                            </ShinyButtonWhite>
                        </Grid>
                    </>
                )}
            </Grid>
            <WalletDialog open={open} handleClose={handleClose} />
        </Grid>
    );
}
