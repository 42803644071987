/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */

import { CancelToken, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { ApiException, BitaxAPIClient } from './BitaxApiClient';

class BitaxApiClientExtended extends BitaxAPIClient {

    /**
     * Downloads a Report file.
     * @param taxReportId (optional)
     * @return Success
     */
    downloadTaxReport(taxReportId: string | undefined, cancelToken?: CancelToken | undefined): Promise<void> {
        let url_ = (this as any).baseUrl + '/TaxReports/download?';
        if (taxReportId === null)
            throw new Error('The parameter \'taxReportId\' cannot be null.');
        else if (taxReportId !== undefined)
            url_ += 'taxReportId=' + encodeURIComponent('' + taxReportId) + '&';
        url_ = url_.replace(/[?&]$/, '');

        let options_: AxiosRequestConfig = {
            method: 'GET',
            responseType: 'blob',
            url: url_,
            headers: {
            },
            cancelToken
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return (this as any).instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processDownloadTaxReport(_response);
        });
    }

    protected processDownloadTaxReport(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === 'object') {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve(_responseText);

        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException('An unexpected server error occurred.', status, _responseText, _headers);
        }
        return Promise.resolve<void>(null as any);
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export { BitaxApiClientExtended };