import { styled } from '@mui/material/styles'
import { Avatar, AvatarProps, Button, ButtonProps } from '@mui/material';
import React, { ReactElement, Ref } from 'react';
import { bitaxGradients, bitaxTints } from '../styles/constants';

const StyledDarkButton = styled(Button)({
    transition: 'all 0.2s',
    height: '3.5rem',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '24px',
    padding: '6px 24px',
    textTransform: 'initial',
    borderRadius: '50px',
    backgroundColor: bitaxTints.buttonGrey,
    color: bitaxTints.buttonBlue,
    '&:hover': {
        backgroundColor: bitaxTints.buttonGrey,
        transform: 'scale(1.1)'
    },
});
const StyledDarkButtonWhite = styled(Button)({
    transition: 'all 0.2s',
    height: '3.5rem',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '24px',
    padding: '6px 24px',
    textTransform: 'initial',
    borderRadius: '50px',
    backgroundColor: bitaxTints.buttonGrey,
    color: bitaxTints.white,
    '&:hover': {
        backgroundColor: bitaxTints.buttonGrey,
        transform: 'scale(1.1)'
    },
});
const StyledShinyButton = styled(Button)({
    transition: 'all 0.2s',
    height: '3.5rem',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '24px',
    padding: '6px 24px',
    textTransform: 'initial',
    borderRadius: '50px',
    backgroundImage: bitaxGradients.lightBlueMain,
    color: bitaxTints.darkBackground
    ,
    '&:hover': {
        backgroundColor: bitaxGradients.lightBlueMain,
        transform: 'scale(1.05)'
    },
});
const StyledAvatarButton = styled(Avatar)({
    transition: 'all 0.2s',
    height: '3.5rem',
    width: '3.5rem',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '24px',
    padding: '6px 24px',
    textTransform: 'initial',
    borderRadius: '50px',
    backgroundColor: bitaxTints.buttonGrey,
    color: bitaxTints.buttonBlue,
    '&:hover': {
        backgroundColor: bitaxTints.buttonGrey,
        transform: 'scale(1.1)'
    },
    '& .MuiSvgIcon-root': {
        color: bitaxTints.buttonBlue,
    }
});

// TODO: remove eslint overrides below
// eslint-disable-next-line react/display-name
export const DarkButton = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (
        { children, ...props }: ButtonProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _ref: Ref<HTMLDivElement>
    ): ReactElement => (
    // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledDarkButton
            variant="contained"
            disabled={props.disabled}
            disableRipple
            {...props}
        >
            {children}
        </StyledDarkButton>
    )
);

// TODO: remove eslint overrides below
// eslint-disable-next-line react/display-name
export const DarkButtonWhite = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (
        { children, ...props }: ButtonProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ref: Ref<HTMLDivElement>
    ): ReactElement => (
    // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledDarkButtonWhite
            variant="contained"
            disabled={props.disabled}
            disableRipple
            {...props}
        >
            {children}
        </StyledDarkButtonWhite>
    )
);

// TODO: remove eslint overrides below
// eslint-disable-next-line react/display-name
export const ShinyButtonWhite = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (
        { children, ...props }: ButtonProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _ref: Ref<HTMLDivElement>
    ): ReactElement => (
    // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledShinyButton
            variant="contained"
            disabled={props.disabled}
            disableRipple
            {...props}
        >
            {children}
        </StyledShinyButton>
    )
);

// eslint-disable-next-line react/display-name
export const AvatarButton = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (
        { children, ...props }: AvatarProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _ref: Ref<HTMLDivElement>
    ): ReactElement => (
    // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledAvatarButton
            {...props}
        >
            {children}
        </StyledAvatarButton>
    )
);

