/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_BitaxDev',
        /* editProfile: 'B2C_1_edit_profile_v2' */
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://bitaxdev.b2clogin.com/bitaxdev.onmicrosoft.com/B2C_1_BitaxDev',
        },
        /*
        editProfile: {
            authority: 'https://bitaxdev.b2clogin.com/bitaxdev.onmicrosoft.com/'
        } */
    },
    authorityDomain: 'bitaxdev.b2clogin.com'
}