import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as BitaxLogo } from '../assets/icons/bitax-logo.svg';
import { styled, SvgIcon, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { bitaxTints, GradientText } from '../styles/constants';

const CustomLink = styled(Link)({
    textDecoration: 'none',
    paddingRight: '24px',
    paddingLeft: '24px'
});

const CustomDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        background: theme.palette.background.default,
        width: '240px',
    }
}))

export interface DrawerProps {
  pages: string[];
  links: string[];
  location: string;
}

const TemporaryDrawer = ({pages, links, location}: DrawerProps): React.ReactElement => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const anchor= 'left';
    const toggleDrawer =
    (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            setDrawerOpen(open);
        };

    return (
        <React.Fragment>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                sx={{background: bitaxTints.buttonGrey, height: '3.5rem', width: '3.5rem'}}
            >
                <MenuIcon />
            </IconButton>
            <CustomDrawer
                anchor={anchor}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem key='icon' disablePadding>
                            <SvgIcon
                                component={BitaxLogo}
                                inheritViewBox
                                sx={{ display: { xs: 'none', md: 'flex' }, height: '35px', width: '55px'}}
                            />
                        </ListItem>
                        {pages.map((page, index) => (
                            <ListItem key={page} disablePadding>
                                <CustomLink
                                    key={page}
                                    to={links[index]}
                                >
                                    {location === links[index] ? (
                                        <GradientText variant="subtitle1" sx={{ my: 2, display: 'block' }}>{page}</GradientText>
                                    ):(
                                        <Typography variant="subtitle1" sx={{ my: 2, color: bitaxTints.secondaryText, display: 'block' }}>{page}</Typography>
                                    )}
                                </CustomLink>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </CustomDrawer>
        </React.Fragment>
    );
}
export default TemporaryDrawer;