import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    Grid,
    styled,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bitaxTints } from '../../styles/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import React, { useEffect, useState } from 'react';
import { ShinyButtonWhite } from '../../components/Buttons';
import DownloadIcon from '@mui/icons-material/Download';
// import { useBitaxContext } from '../../store/BitaxContext';
import { BitaxApiClientExtended as BitaxAPIClient } from '../../services/BitaxApiClient.extensions';
import { TaxReportSummaryDto } from '../../services/BitaxApiClient';
import { DateTime } from 'luxon';

const StyledListWalletBox = styled(Grid)({
    width: 'inherit',
    border: `1px solid ${bitaxTints.buttonGrey}`,
    borderRadius: '16px',
});

const StyledAccordion = styled(Accordion)({
    background: 'transparent',
    '& .MuiAccordionSummary-root': {
        padding: '0px',
    },
    '& .MuiAccordionDetails-root': {
        padding: '0px',
        paddingTop: '0.5rem',
    },
    '& path': {
        fill: bitaxTints.buttonBlue,
    },
});

const AccordionSummaryText = styled(Typography)({
    color: bitaxTints.buttonBlue,
    width: '33%',
    flexShrink: 0,
    textAlign: 'start',
});

const AlignedText = styled(Typography)({
    textAlign: 'initial',
});

const AccordionGrid = styled(Grid)({
    paddingTop: '1.5rem',
});
/*
const StyledChip = styled(Chip)({
    background: bitaxTints.buttonBlue,
    fontSize: '12px',
    fontWeight: 600,
});
*/
const StyledDownloadIcon = styled(DownloadIcon)({
    '& path': {
        fill: bitaxTints.darkBackground,
    },
});

const GradientIcon = () => (
    <>
        <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={0} y1={0} x2={1} y2={0}>
                <stop offset={0} stopColor="rgba(86, 145, 255, 1)" />
                <stop offset={1} stopColor="rgba(68, 235, 202, 1)" />
            </linearGradient>
        </svg>
        <AssessmentIcon sx={{ fill: 'url(#linearColors)', mr: '1.5rem', scale: '1.7'}} />
    </>
)

export default function Taxes() {
    const { t } = useTranslation('bitax');
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [taxReports, setTaxReports] = useState<TaxReportSummaryDto[]>([]);
    const [expanded, setExpanded] = React.useState<number>(0);
    // const [file, setFile] = React.useState<void>();
    // const { generating } = useBitaxContext();
    // TODO: move URL to config
    const client = new BitaxAPIClient('http://localhost:3000');

    const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : 0);
    };

    const getUserTaxReports = async () => {
        setIsLoading(true)
        const newTaxReports = await client.taxReports();
        console.log('🚀 ~ file: taxes.tsx:101 ~ getUserTaxReports ~ newTaxReports:', newTaxReports)
        setTaxReports(newTaxReports)
        setExpanded(newTaxReports.length > 0 ? 1 : 0)
        setIsLoading(false)
    }
    useEffect(() => {
        getUserTaxReports();
    },[])

    const handleDownload = async (taxReportId: string) => {
        setIsDownloading(true)

        // Create the file content
        // const response = await client.taxReportsDownload(taxReportId);
        const response = await client.downloadTaxReport(taxReportId);
        // setFile(response)
        setIsDownloading(false)

        if(response !== undefined){


            const fileContent: BlobPart[] = [response]; // Convert the response to an array of BlobParts


            // Create a Blob object with the file content and specify the file type
            const blob = new Blob(fileContent, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Generate a URL for the blob object
            const downloadUrl = URL.createObjectURL(blob);


            // Create a temporary link element
            const link = document.createElement('a');
            link.href = downloadUrl
            link.download = 'CryptoTaxReport.xlsx'; // Specify the desired filename

            // Append the link to the document body and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up the temporary link and URL object
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
        }
    };

    return (
        <Grid container sx={{ marginTop: '5rem', justifyContent: 'center' }}>
            <Grid container item sx={{ maxWidth: '32rem' }}>
                <Grid container item sx={{ justifyContent: 'space-between' }}>
                    <Grid item>
                        <Box sx={{display: 'flex', alignItems: 'center', mx: {xs: 'auto', sm: '0px'}, width: {xs: 'fit-content', sm: 'auto'}}}>
                            <GradientIcon />
                            <Typography variant="h1">{t('tax_reports.tax_reports')}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item>
                    {isLoading === false ? ( taxReports.map((taxReport: TaxReportSummaryDto, index) => (
                        <StyledListWalletBox
                            key={`panel${taxReport.id}`}
                            container
                            item
                            sx={
                                index === 0
                                    ? { marginTop: '2.25rem', width: 'inherit' }
                                    : { marginTop: '1.5rem', width: 'inherit' }
                            }
                        >
                            <Grid
                                container
                                item
                                sx={{ margin: '1rem 2.25rem', alignItems: 'center' }}
                            >
                                <Grid item xs>
                                    <StyledAccordion
                                        disableGutters
                                        elevation={0}
                                        expanded={expanded === index + 1}
                                        onChange={handleChange(index)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${taxReport.id}control`}
                                            id={`panel${taxReport.id}header`}
                                        >
                                            <AccordionSummaryText variant="body1">
                                                {taxReport.createdTimestamp
                                                    ? DateTime.fromJSDate(taxReport.createdTimestamp).toRelative({locale: 'fi'})
                                                    : 'no date data'}
                                            </AccordionSummaryText>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container item>
                                                <Grid item xs>
                                                    <AlignedText variant="h2">
                                                        {taxReport.profit + '€'}
                                                    </AlignedText>
                                                    <AlignedText variant="body2">
                                                        {t('tax_reports.total_gains')}
                                                    </AlignedText>
                                                </Grid>
                                                <Grid item xs>
                                                    <AlignedText variant="h2" sx={{ textAlign: 'end' }}>
                                                        {0 + '€'}
                                                    </AlignedText>
                                                    <AlignedText
                                                        variant="body2"
                                                        sx={{ textAlign: 'end' }}
                                                    >
                                                        {t('tax_reports.taxes_to_pay')}
                                                    </AlignedText>
                                                </Grid>
                                            </Grid>
                                            {/* TODO: communicate with Eetu on what stats we should show.
                                            <AccordionGrid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={1}
                                            >
                                                {taxReport.stats.map((stat: NameValue, index) => (
                                                    <Grid item key={`stats${index}`}>
                                                        <StyledChip
                                                            label={`${stat.value} ${t(stat.name)}`}
                                                            size="small"
                                                        />
                                                    </Grid>
                                                ))}
                                            </AccordionGrid>
                                            <AccordionGrid>
                                                {taxReport.breakdown.map((item: NameValue, index) => (
                                                    <Grid
                                                        key={`breakdown${index}`}
                                                        container
                                                        item
                                                        xs={12}
                                                        sx={{ paddingBottom: '0.75rem' }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    textAlign: 'start',
                                                                    color: bitaxTints.secondaryText,
                                                                }}
                                                            >
                                                                {t(item.name)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                sx={{ textAlign: 'end' }}
                                                                variant="body1"
                                                            >
                                                                {item.value + '€'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </AccordionGrid>
                                                            */}
                                            <AccordionGrid sx={{ paddingBottom: '0.75rem' }}>
                                                {isDownloading ? (
                                                    <ShinyButtonWhite
                                                        sx={{ width: 'inherit', heigh: '2.25rem'}}
                                                        onClick={() => {
                                                            if(taxReport.id !== undefined){
                                                                handleDownload(taxReport.id)
                                                            }
                                                        }}
                                                        disabled
                                                    >
                                                        <CircularProgress color='inherit' sx={{color: bitaxTints.darkBackground, scale: 0.5, mr: 1}}/>
                                                        {t('tax_reports.download_taxes')}
                                                    </ShinyButtonWhite>
                                                ):(
                                                    <ShinyButtonWhite
                                                        sx={{ width: 'inherit', heigh: '2.25rem'}}
                                                        onClick={() => {
                                                            if(taxReport.id !== undefined){
                                                                handleDownload(taxReport.id)
                                                            }
                                                        }}
                                                    >
                                                        <StyledDownloadIcon sx={{color: bitaxTints.darkBackground, height: '24px', mr: 1}} />
                                                        {t('tax_reports.download_taxes')}
                                                    </ShinyButtonWhite>
                                                )}

                                            </AccordionGrid>
                                        </AccordionDetails>
                                    </StyledAccordion>
                                </Grid>
                            </Grid>
                        </StyledListWalletBox>
                    ))):(
                        // Loading Spinner
                        <Grid container item sx={{ marginTop: '2.25rem', height: '10rem', width: 'inherit', justifyContent: 'center' }}>
                            <Grid item xs={12} sx={{ display: 'grid', alignContent: 'center', justifyContent: 'center', color: bitaxTints.buttonBlue }}>
                                <CircularProgress color='inherit'/>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'grid', height: 'fit-content', alignContent: 'center', color: bitaxTints.buttonBlue }}>

                                <Typography>{t('general.loading')}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
