import {
    Dialog,
    Grid,
    ListItem,
    ListItemButton,
    ListItemText,
    styled,
    TextField,
    Typography,
} from '@mui/material';
import { bitaxTints } from '../../styles/constants';

const StyledListWalletBox = styled(Grid)({
    width: 'inherit',
    border: `1px solid ${bitaxTints.buttonGrey}`,
    borderRadius: '16px',
});

const AlignedText = styled(Typography)({
    textAlign: 'initial',
});

const WalletDialogMain = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: bitaxTints.buttonGrey,
        backgroundImage: 'none',
        borderRadius: theme.shape.borderRadius,
        minHeight: '20rem',
    },
}));

const WalletListItem = styled(ListItem)({
    height: '4rem',
});

const WalletListItemButton = styled(ListItemButton)(({ theme }) => ({
    height: '4rem',
    borderRadius: theme.shape.borderRadius,
}));

const WalletSecondaryActionTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        visibility: 'hidden',
    },
}));

const NoticeTypography = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(4),
    color: theme.palette.grey[500],
}));

const WalletNameTextField = styled(TextField)(({ theme }) => ({
    '& input:valid + fieldset': {
        borderColor: theme.palette.info.main,
        borderWidth: 1,
    },
    '& input:invalid + fieldset': {
        borderColor: theme.palette.error.main,
        borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: 1,
        padding: '4px !important', // override inline-style
    },
    '& input:invalid:focus + fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: 1,
        padding: '4px !important', // override inline-style
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#E7EBEF',
    },
}));

const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
        ...theme.typography.subtitle2,
    },
    '& .MuiListItemText-secondary': {
        ...theme.typography.body1,
        color: bitaxTints.grey6,
    },
}));

export {
    StyledListWalletBox,
    AlignedText,
    WalletDialogMain,
    WalletListItem,
    WalletListItemButton,
    WalletSecondaryActionTypography,
    NoticeTypography,
    WalletNameTextField,
    ListItemTextStyled,
};
