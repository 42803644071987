import {
    DialogProps,
} from '@mui/material';
import React, { useState } from 'react';
import { WalletSetup } from './WalletSetup';
import { WalletSelect } from './WalletSelect';
import { WalletDialogMain } from '../walletStyles';
import { mockWallets } from '../../../store/fake-data';
import { WalletType, walletTypes } from '../../../store/types';

export enum DialogPage {
    SELECT_WALLET_TYPE = 0,
    SETUP_WALLET = 1
}

interface IMyProps {
  open: boolean;
  handleClose: () => void;
}

export const WalletDialog = ({ open, handleClose }: IMyProps) => {
    const [page, setPage] = useState<DialogPage>(DialogPage.SELECT_WALLET_TYPE);

    const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');
    // Might want to separate this wallet list somewhere else.
    const [selectedWallet, setSelectedWallet] = useState<WalletType>();

    return (
        <WalletDialogMain fullWidth maxWidth={maxWidth} open={open}>
            {page === DialogPage.SELECT_WALLET_TYPE && (
                <WalletSelect
                    setPage={setPage}
                    handleClose={handleClose}
                    walletTypes={walletTypes}
                    usersWallets={mockWallets}
                    setSelectedWallet={setSelectedWallet}
                />
            )}
            {page === DialogPage.SETUP_WALLET && (
                <WalletSetup
                    setPage={setPage}
                    handleClose={handleClose}
                    walletTypes={walletTypes}
                    selectedWallet={selectedWallet}
                />
            )}
        </WalletDialogMain>
    );
};
