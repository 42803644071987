import React, { ReactElement, ReactNode } from 'react';
import './App.css';
import {
    Box,
    createTheme,
    CssBaseline,
    Paper,
    ThemeProvider,
} from '@mui/material';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Wallets from './pages/wallets/wallets'; 
import { themeOptions, themeOptionsLight } from './styles/theme';
import Taxes from './pages/taxes/taxes';
import Testing from './pages/testing';

import { useIsAuthenticated } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import LoginLander from './components/LoginLander';
import { BitaxProvider } from './store/BitaxContext';


const themeLight = createTheme(themeOptionsLight);
const themeDark = createTheme(themeOptions);

function BfThemeProvider({
    light,
    children,
}: {
  light: boolean;
  children: ReactNode;
}) {
    return (
        <ThemeProvider theme={light ? themeLight : themeDark}>
            {children}
        </ThemeProvider>
    );
}

const Placeholder = ({ title }: { title: string }): ReactElement => (
    <Box m={2}>
        <Paper>Placeholder content for {title}</Paper>
    </Box>
);

const queryClient = new QueryClient();

export default function App() {
    const [light, setLight] = React.useState(false);
    function changeTheme() {
        setLight(!light);
    }
    const isAuthenticated = useIsAuthenticated();

    return (
        <QueryClientProvider client={queryClient}>
            <BfThemeProvider light={light}>
                <BitaxProvider>
                    <CssBaseline />
                    <div className="App">
                        {/* <RouterProvider router={router} />*/}
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/"
                                    element=
                                        {isAuthenticated ? (
                                            <NavBar
                                                changeTheme={changeTheme}
                                                loggedIn={isAuthenticated}
                                            />
                                        ):(
                                            <LoginLander/>
                                        )}
                                >
                                    <Route index element={<Wallets />} />
                                    <Route path="transactions" element={<Placeholder title="transactions"/>} />
                                    <Route path="tax-reports" element={<Taxes />} />
                                    <Route path="testing" element={<Testing />} />
                                    
                                    <Route path="*" element={<NoMatch />} />
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </div>
                </BitaxProvider>
            </BfThemeProvider>
        </QueryClientProvider>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}
