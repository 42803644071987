import React from 'react';
import { styled } from '@mui/material/styles'
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { bitaxTints, borderRadius } from '../../../styles/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BitaxAPIClient, WalletDto } from '../../../services/BitaxApiClient';
import { useBitaxContext } from '../../../store/BitaxContext';

const MenuButton = styled(Button)({
    marginLeft: '0.5rem', 
    background: 'transparent',
    '&:hover': {
        transform: 'scale(1.2)'
    }
})

const StyledMenuItem = styled(MenuItem)({
    '.MuiSvgIcon-root':{
        scale: '0.8',
        marginRight: '0.5rem'
    }
})

const StyledMenu = styled(Menu)({
    '.MuiPaper-root':{
        background: bitaxTints.buttonGrey,
        borderRadius: borderRadius,
    }
})

const ConfirmationDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: bitaxTints.buttonGrey,
        backgroundImage: 'none',
        borderRadius: theme.shape.borderRadius,
        // minHeight: '20rem',
    },
}));

const StyledDialogButton = styled(Button)({
    margin: '0.5rem',
    '.MuiSvgIcon-root':{
        scale: '0.8',
        marginRight: '0.2rem'
    },
    '&:hover': {
        transform: 'scale(1.1)'
    }
})

interface IMyProps {
    wallet: WalletDto
  }


export const WalletMenuButton = ({ wallet }: IMyProps) => {
    const { t } = useTranslation('bitax');

    const { updateWallets } = useBitaxContext();
    
    // Menu handlers ---
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // ---

    // Dialog handlers ---
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleDialogClickOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    // ---

    // API calls ---
    // TODO: move URL to config

    const handleUpdateWallets = async () => {
        const wallets = await client.walletsGet();
        updateWallets(wallets);
    }

    const client = new BitaxAPIClient('http://localhost:3000');

    const WalletDelete = async (wallet: WalletDto) => {
        try {
            await client.walletsDelete(wallet.id);
            handleUpdateWallets();

        } catch (error) {
            // TODO: proper error handling
            console.log('Error while creating new wallet:', error);
            throw error;
        }
    }
    // ---
  
    return (
        <div>
            <MenuButton 
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={open ? {color: bitaxTints.grey2} : {color: bitaxTints.buttonBlue}} 
                onClick={handleClick}
            >
                <MoreVertIcon />
            </MenuButton>

            {/* Action Menu */}
            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Tooltip title={t('wallets.menu.refresh_tip') || ''} placement="left">
                    {/* TODO: Add disabled functionality based on wallet.type */}
                    <StyledMenuItem disabled onClick={handleClose}> 
                        <RefreshIcon />
                        <Typography variant="body1">{t('wallets.menu.refresh')}</Typography>
                    </StyledMenuItem>
                </Tooltip>
                <Tooltip title={t('wallets.menu.disable_tip') || ''} placement="left">
                    <StyledMenuItem onClick={handleClose}>
                        <NotificationsPausedIcon />
                        <Typography variant="body1">{t('wallets.menu.disable')}</Typography>
                    </StyledMenuItem>
                </Tooltip>
                <Tooltip title={t('wallets.menu.delete_tip') || ''} placement="left">
                    <StyledMenuItem onClick={()=>{
                        handleClose()
                        handleDialogClickOpen()
                    }}>
                        <DeleteForeverIcon color="error"/>
                        <Typography variant="body1" color="error">{t('wallets.menu.delete')}</Typography>
                    </StyledMenuItem>
                </Tooltip>
            </StyledMenu>

            {/* Deletion Confirmation Dialog */}
            <ConfirmationDialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h3'>
                        {t('wallets.menu.dialog.header_part1')}
                        {wallet.userComments}
                        {t('wallets.menu.dialog.header_part2')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="body1" color={bitaxTints.secondaryText}>
                            {t('wallets.menu.dialog.are_you_sure')} 
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledDialogButton onClick={handleDialogClose} sx={{color: bitaxTints.white}}>
                        <ArrowBackIcon/>
                        <Typography variant="subtitle2">
                            {t('wallets.menu.dialog.back')}
                        </Typography>
                    </StyledDialogButton>
                    <StyledDialogButton onClick={()=> {
                        handleDialogClose()
                        WalletDelete(wallet)
                    }} 
                    autoFocus>
                        <DeleteForeverIcon color="error"/>
                        <Typography variant="subtitle2" color="error">
                            {t('wallets.menu.dialog.delete')}
                        </Typography>
                    </StyledDialogButton>
                </DialogActions>
            </ConfirmationDialog>
        </div>
    );
}

