import { AxiosRequestConfig } from 'axios';
import { loginRequest, msalConfig } from '../authentication/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';

// This should be the same instance you pass to MsalProvider
export class BitaxAPIClientBase {
    public async transformOptions(
        options: AxiosRequestConfig
    ): Promise<AxiosRequestConfig> {
        const msalInstance = new PublicClientApplication(msalConfig);

        const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = msalInstance.getAllAccounts();

        if (!activeAccount && accounts.length === 0) {
            /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
        }

        const authResult = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: activeAccount || accounts[0],
        });

        if (options.headers) {
            options.headers.Authorization = 'Bearer ' + authResult.accessToken;
        }
        return Promise.resolve(options);
    }
}
