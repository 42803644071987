import {
    Box,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    SvgIcon,
    Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UploadIcon from '@mui/icons-material/Upload';
import {
    BitaxAPIClient,
    CSVConfigurationCommand,
    FileParameter,
} from '../../../services/BitaxApiClient';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Autorenew } from '../../../assets/icons/autorenew.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { WalletType } from '../../../store/types';
import { ListItemTextStyled, WalletNameTextField } from '../walletStyles';
import { borderRadius } from '../../../styles/constants';
import { CreateWalletCommand } from '../../../services/BitaxApiClient';
import { DialogPage } from './WalletDialog';
import { useBitaxContext } from '../../../store/BitaxContext';

// placeholder solution. We need dedicated place for these later.
const coinbaseAuthUrl =
  'https://www.coinbase.com/oauth/authorize?' +
  'response_type=code' +
  '&client_id=a328b454bdfbb768df59811e9901f6e40480b02ad476ac842fdc88cd9e9af5e9' +
  '&redirect_uri=http://localhost:3000/' +
  '&state=134ef5504a94' +
  '&scope=wallet:user:read,wallet:transactions:read';

export type WalletSetupProps = {
    setPage: React.Dispatch<React.SetStateAction<number>>;
    handleClose: () => void;
    walletTypes: WalletType[];
    selectedWallet: WalletType | undefined;
};

export const WalletSetup = ({
    setPage,
    handleClose,
    selectedWallet,
}: WalletSetupProps) => {

    const { t } = useTranslation('bitax');
    // TODO: move URL to config
    const client = new BitaxAPIClient('http://localhost:3000');

    const { updateWallets } = useBitaxContext();

    const handleUpdateWallets = async () => {
        const wallets = await client.walletsGet();
        updateWallets(wallets);
    }

    const [name, setName] = React.useState('');
    const [error, setError] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setError(true);
        } else if (error === true) {
            setError(false);
        }
        setName(event.target.value);
    };

    async function sendCSV(file: File | undefined) {

        if (!file) {
            // TODO: proper error handling
            console.error('WalletDialog.sendCSV: File was null!');
            return;
        }

        const createWalletCmd = new CreateWalletCommand({
            userComments: name,
            isEnabled: true,
            // TODO: get values from config or ask from user
            csvConfiguration: new CSVConfigurationCommand({
                columnSeparator: '\t',
                decimalSeparator: ',',
                hasHeaderRow: false
            })
        });

        try {

            const wallet = await client.walletsPost(createWalletCmd);
            const fileParameter: FileParameter = { data: file, fileName: file.name };
            await client.walletsSyncCsv(wallet.id as string, fileParameter);
            handleUpdateWallets();
            

        } catch (error) {

            // TODO: proper error handling
            console.log('Error while creating new wallet:', error);
            throw error;
        }
    }

    return (
        <>
            <DialogTitle sx={{ textAlign: 'center' }}>
                {selectedWallet?.name}
                <IconButton
                    aria-label="close"
                    onClick={() => setPage(DialogPage.SELECT_WALLET_TYPE)}
                    sx={{
                        position: 'absolute',
                        left: 10,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item container m={2} mb={4}>
                        <Grid item xs={12} mb={1}>
                            <Typography variant="subtitle1">
                                {' '}
                                {t('wallets.wallet_name') + ':'}{' '}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Box component="form" noValidate>
                                <WalletNameTextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {selectedWallet?.logo !== undefined && (
                                                    <SvgIcon
                                                        component={selectedWallet.logo}
                                                        inheritViewBox
                                                    />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={error}
                                    value={name}
                                    onChange={handleChange}
                                    id="name"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container m={2}>
                        <Grid
                            item
                            xs={12}
                            sx={error || name === '' ? { opacity: 0.38 } : {}}
                        >
                            <Typography variant="subtitle1">
                                {' '}
                                {t('wallets.import_data')}{' '}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={
                                            selectedWallet?.name === t('wallets.names.manual')
                                                ? { display: 'none' }
                                                : { height: '5rem', borderRadius: borderRadius }
                                        }
                                        disabled={error || name === ''}
                                        component="a"
                                        href={coinbaseAuthUrl}
                                    >
                                        <ListItemIcon>
                                            <Autorenew />
                                        </ListItemIcon>
                                        <ListItemTextStyled
                                            primary={t('wallets.automatic.header')}
                                            secondary={t('wallets.automatic.sub')}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={{ height: '5rem', borderRadius: borderRadius }}
                                        component="label"
                                        disabled={error || name === ''}
                                    >
                                        <ListItemIcon>
                                            <UploadIcon sx={{ height: '2.5rem', width: '2.5rem' }} />
                                        </ListItemIcon>
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(e) => {
                                                if (e?.target?.files?.[0] !== null) {
                                                    sendCSV(e?.target?.files?.[0]);
                                                    handleClose()
                                                }
                                            }}
                                        />
                                        <ListItemTextStyled
                                            primary={t('wallets.manual.header')}
                                            secondary={t('wallets.manual.sub')}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

/* Nice example how to use Axios and React Hooks
Axios: https://axios-http.com/docs/example
React Hooks: https://react-query.tanstack.com/examples/custom-hooks <-- We'll see if this is useless.

const getWalletCode = async () => {
    const { data } = await axios.get('https://www.coinbase.com/oauth/authorize', {
        headers: { "Access-Control-Allow-Origin": "*" },
        params: {
            response_type: 'code',
            client_id: 'a328b454bdfbb768df59811e9901f6e40480b02ad476ac842fdc88cd9e9af5e9',
            redirect_uri: 'http://localhost:3000/',
            scope: 'wallet:transactions:read',
        }
    })
    .then(function (response) {
        console.log(response);
        let responseHtml = response.data;
        console.log(responseHtml, "Monitoring");
        //open the new window and write your HTML to it
        var myWindow = window.open("", "_blank");
        myWindow?.document.write(responseHtml);
        return response.data;
    })
    .catch(function (error) {
        console.log(error);
    })
    return data;
};

function usePost () {
    return useQuery("repoData", () => getWalletCode())
}
*/
// document.location.search <--- this should get the code parameter
