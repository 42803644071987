import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles'
import SvgIcon from '@mui/icons-material/Menu';
import { ReactComponent as BitaxLogo } from '../assets/icons/bitax-logo.svg';
import { ReactComponent as FiFlag } from '../assets/icons/flags/FI.svg';
import { ReactComponent as GBFlag } from '../assets/icons/flags/GB.svg';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import i18next from 'i18next';
import { Language } from '../store/types';
import { Link } from 'react-router-dom';
import { AvatarButton, DarkButton } from './Buttons';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authentication/authConfig';
import Drawer from './Drawer';

import { SpeedDial, SpeedDialAction, Switch } from '@mui/material';
import { bitaxTints, GradientText } from '../styles/constants';
import { BitaxAPIClient, IUserDto } from '../services/BitaxApiClient';


const CustomToolbar = styled(Toolbar)({
    height: '5.5rem',
    background: 'transparent',
});
const CustomAppBar = styled(AppBar)({
    height: '5.5rem',
    background: 'transparent',
});
const CustomLink = styled(Link)({
    textDecoration: 'none',
    paddingRight: '24px'
});
const CustomSpeedDial = styled(SpeedDial)({
    '& .MuiSpeedDial-fab': {
        boxShadow: 'none',
    },
});
const LightDarkSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#181E25' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: '\'\'',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#181E25' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));
  
export interface NavBarProps {
  changeTheme: () => void;
  loggedIn: boolean;
}

const languages = [
    { key: 1, icon: <FiFlag />, name: 'fi' },
    { key: 2, icon: <GBFlag />, name: 'en' },
];

const ResponsiveAppBar = ({ changeTheme, loggedIn }: NavBarProps): React.ReactElement => {
    const location = useLocation();
    console.log(location)
    const { t } = useTranslation('bitax');
    const [lang, setLang] = React.useState<Language>({
        key: 1,
        icon: <FiFlag />,
        name: 'fi',
    });
    const pages = [t('navbar.wallets'),t('navbar.transactions'), t('navbar.tax_reports'),'testing'];
    const links = ['/', '/transactions', '/tax-reports', '/testing']

    const changeLanguage = (selectedLang: Language) => {
        i18next.changeLanguage(selectedLang.name, (err) => {
            if (err) return console.log('something went wrong loading', err);
        });
        setLang(selectedLang);
    };


    // TODO: move URL to config
    const client = new BitaxAPIClient('http://localhost:3000');
    const [user, setUser] = React.useState<IUserDto|null>(null);

    const GetUser = async () => {
        const newUser = await client.usersSelf();
        setUser(newUser);
    }
    React.useEffect(() => {
        GetUser();
    },[])

    const { instance } = useMsal();

    const handleLogin = (loginType: string) => {
        if (loginType === 'popup') {
            instance.loginPopup(loginRequest).catch((e) => {
                console.error(e);
            });
        } else if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.error(e);
            });
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <CustomAppBar position="static" elevation={0}>
                <Container maxWidth="xl">
                    <CustomToolbar disableGutters>
                        <SvgIcon
                            component={BitaxLogo}
                            inheritViewBox
                            sx={{ display: { xs: 'none', sm: 'flex' }, height: '35px', width: '55px'}}
                        />
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                            <Drawer pages={pages} links={links} location={location.pathname}/>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
                            {pages.map((page, index) => (
                                <CustomLink
                                    key={page}
                                    to={links[index]}
                                >
                                    {location.pathname === links[index] ? (
                                        <GradientText variant="subtitle1" sx={{ my: 2, display: 'block' }}>{page}</GradientText>
                                    ):(
                                        <Typography variant="subtitle1" sx={{ my: 2, color: bitaxTints.secondaryText, display: 'block' }}>{page}</Typography>
                                    )}
                                </CustomLink>
                            ))}
                        </Box>
                        <Box>
                            <Tooltip title={'Light and dark mode'} sx={{display: {xs:'flex', md:'none'}}}>
                                <LightDarkSwitch sx={{ m: 1 }} defaultChecked onClick={changeTheme}/>
                            </Tooltip>
                        </Box>

                        <Box sx={{ flexGrow: 0, marginRight: '4.5rem' }}>
                            
                            <Tooltip title={'User settings'} sx={{display: {xs:'flex', md:'none'}}}>
                                {loggedIn ? (
                                    <AvatarButton>
                                        <PersonIcon />
                                    </AvatarButton>
                                ):(
                                    <AvatarButton onClick={() => handleLogin('redirect')}>
                                        <LoginIcon />
                                    </AvatarButton>
                                )}
                            </Tooltip>

                            <Tooltip title={'User settings'} sx={{display: {xs:'none', md:'flex'}}}>
                                {loggedIn ? (
                                    <DarkButton
                                        onClick={() => console.log('TODO: Make user settings.')}
                                        startIcon={<PersonIcon sx={{height: '24px', width: '24px'}}/>}
                                    >
                                        <Typography sx={{display: {xs:'none', md:'flex'}}}>{user?.username ?? ''}</Typography>
                                    </DarkButton>
                                ) : (
                                    <DarkButton
                                        onClick={() => handleLogin('popup')}
                                        startIcon={<LoginIcon />}
                                    >
                                        {t('navbar.login')}
                                    </DarkButton>
                                )}
                            </Tooltip>
                        </Box>
                        <CustomSpeedDial
                            ariaLabel="languagesDial"
                            sx={{
                                position: 'absolute',
                                bottom: -112,
                                right: 0,
                                boxShadow: 'none',
                            }}
                            icon={lang.icon}
                            direction="down"
                        >
                            {languages.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name === 'fi' ? 'Suomi' : 'English' }
                                    onClick={() => changeLanguage(action)}
                                />
                            ))}
                        </CustomSpeedDial>
                    </CustomToolbar>
                </Container>
            </CustomAppBar>
            <Outlet />
        </Box>
    );
}
export default ResponsiveAppBar;